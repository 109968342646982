<template>
	<div>
		<Sidebar />
		<div class="grid__body">
			<Header />
			<main class="main">
				<router-view :user="user" />
			</main>
			<Footer />
		</div>
	</div>
</template>

<script>
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default {
	name: 'Base',
	components: {
		Sidebar,
		Footer,
		Header
	},
	data() {
		return {
			user: {}
		};
	}
};
</script>
