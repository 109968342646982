<template>
	<div class="nav__side">
		<div class="nav__logo">
			<router-link :to="{ name: '/' }">
				<img src="../assets/img/ladda-logo.svg" alt="Ladda logo" />
			</router-link>
		</div>
		<ul class="nav__links">
			<li class="nav__item">
				<router-link :to="{ name: 'overview' }" active-class="active" exact>
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Overview
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'investors' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Investors
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'mutual-funds' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Mutual Funds
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'giftCards' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Gift Cards
				</router-link>
			</li>
			<li class="nav__item" v-if="display">
				<router-link :to="{ name: 'clients' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Clients
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'banks' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Banks
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'stocks' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg> </span
					>Stocks
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'settings' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Settings
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'audits' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Audits
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'withdrawalRequests' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Withdrawals
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'orders' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Orders
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'referrals' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg>
					</span>
					Referrals
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'savings' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg> </span
					>Savings
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'commissions' }" active-class="active">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg> </span
					>Commissions
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'investor-mf' }">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg> </span
					>User Mutual Funds
				</router-link>
			</li>
			<li class="nav__item">
				<router-link :to="{ name: 'faqs' }">
					<span class="nav__icon">
						<svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 13a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 
									13zm0-3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5z"
								fill="#828282"
							/>
						</svg> </span
					>Faqs
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Sidebar',
	data() {
		return {
			display: false
		};
	}
};
</script>

<style lang="scss" scoped>
.nav__logo {
	a {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-top: 10px;
		font-size: 24px;
		font-weight: bold;
		color: #034d79;
	}
	img {
		height: 30px;
		width: auto;
		padding-right: 5px;
	}
}
.nav__links {
	overflow-x: hidden;
	overflow-y: scroll;
	top: 60px;
	bottom: 0;
	position: fixed;
	width: 217px;
}
</style>
