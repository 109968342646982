<template>
	<footer class="footer">Copyright &copy; {{ year }} Money Africa. All Rights Reserved.</footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			year: new Date().getFullYear()
		};
	}
};
</script>
