<template>
	<header>
		<nav class="nav__main">
			<div></div>
			<div class="nav__right">
				<div class="nav__profile">
					<button
						class="btn btn__dropdown dropdown-toggle"
						data-toggle="dropdown"
						type="button"
						name="button"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Account
					</button>
					<div class="dropdown-menu nav__dropdown">
						<router-link
							:to="{
								name: 'investor-profile',
								params: {
									userData: loggedUser,
									id: loggedUser.data.id
								}
							}"
							class="dropdown__link"
						>
							View Profile
						</router-link>
						<router-link id="logout" class="dropdown-item" :to="{ name: 'logout' }">
							Logout
						</router-link>
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Header',
	data() {
		return {
			query: ''
		};
	},
	computed: {
		...mapGetters({
			loggedUser: 'getLoggedUser'
		})
	},
	methods: {
		search() {
			this.$router.push({
				name: 'search-users',
				params: {
					query: this.query
				}
			});
			this.query = '';
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown {
	&__link {
		text-align: center;
		padding-bottom: 10px;
		display: block;
		&:hover {
			color: #ffa137;
		}
	}
}
.nav {
	&__img {
		img {
			height: 30px;
			width: 30px;
			border-radius: 50%;
		}
	}
}
</style>
